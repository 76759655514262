import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import SEO from "../components/seo"
import { useTransition, animated, config } from "react-spring"

// Styles
import GlobalStyles from "./home/components/global"

import mockup from "../images/nightlife/waitlist-new.png"

import erik from "../images/cilja.png"
import messenger from "../images/messenger.png"
import allMessages from "../images/all-messages.png"
import queueMessages from "../images/all-queue-messages.png"

function Cilja() {
  useEffect(() => {
    if (window.analytics) {
      window.analytics.track("Visited Ambassador")
    }
  }, [])

  const clickedButton = () => {
    if (window.analytics) {
      window.analytics.track("Clicked Amassador Waitlist Button")
    }
  }

  const clickedInfluencerButton = () => {
    if (window.analytics) {
      window.analytics.track("Clicked Influencer Button")
    }
  }

  return (
    <Container>
      <SEO title="Venteliste" />
      <GlobalStyles />

      <FirstSection>
        <Intro>
          <ImageSectionIntro>
            <IntroImage />
          </ImageSectionIntro>
          <IntroTitle>Hey👋</IntroTitle>
        </Intro>
        <TextBoxIntro>
          <Desc>
            <Yellow>VIBE</Yellow> app'en dropper i København start 2021 for de{" "}
            <Yellow>øverste</Yellow> på ventelisten og udvalgte{" "}
            <Yellow>influencers</Yellow>🤫
          </Desc>
        </TextBoxIntro>
        <a
          onClick={() => clickedButton()}
          href="https://m.me/queueapp?ref=ml-vl-refCode--9gXWSGT"
        >
          <Button>
            <ButtonText>Join ventelisten</ButtonText>
            <MessengerDiv>
              <MessengerIcon />
            </MessengerDiv>
          </Button>
        </a>

        <ImageSectionOne>
          <MockupImageOne />
        </ImageSectionOne>
      </FirstSection>

      <SectionOne>
        <TextDiv>
          <QueueText>Hvad er VIBE?</QueueText>
          <Seperator />
          <Catchline>
            <White>Med</White> VIBE <White>kan du se,</White> hvor{" "}
            <White>der bliver festet</White> fredag aften👀
          </Catchline>
        </TextDiv>
      </SectionOne>

      <SectionMessages>
        <MessagesDesc>
          <MessagesText>Så du slipper for👇</MessagesText>
        </MessagesDesc>
        <MessagesDiv>
          <MessagesImage />
        </MessagesDiv>
      </SectionMessages>
      <SectionMessages>
        <MessagesDesc>
          <MessagesText>Og i stedet får beskeder som dem her👇</MessagesText>
        </MessagesDesc>

        <MessagesDiv>
          <QueueMessagesImage />
        </MessagesDiv>
      </SectionMessages>

      <SectionTwoButton>
        <a
          onClick={() => clickedButton()}
          href="https://m.me/queueapp?ref=ml-vl-refCode--9gXWSGT"
        >
          <Button>
            <ButtonText>Join ventelisten</ButtonText>
            <MessengerDiv>
              <MessengerIcon />
            </MessengerDiv>
          </Button>
        </a>
      </SectionTwoButton>

      <SectionThree>
        <TextDiv>
          <InfluencerHeading>Influencer fra København?</InfluencerHeading>
          <Seperator />

          <ThickHeading>
            Skip ventelisten og bliv <Yellow>verified</Yellow> på VIBE med det
            samme, når <Yellow>betaen</Yellow> dropper🎉
          </ThickHeading>

          <a
            onClick={() => clickedInfluencerButton()}
            href="https://anker493456.typeform.com/to/iWHk8M6Z"
          >
            <Button>
              <ButtonText>Ansøg nu🤠</ButtonText>
            </Button>
          </a>
        </TextDiv>
      </SectionThree>
      <SectionFour>
        <LinkInsta href="https://instagram.com/join_vibe">
          Følg med på Insta'en🤑
        </LinkInsta>
      </SectionFour>
    </Container>
  )
}

export default Cilja

const Container = styled.div`
  width: 100vw;
  height: 100%;
  flex: 1;
  max-width: 960px;
  margin: 0 auto;
  padding-right: 50px;
  padding-left: 50px;
  align-items: center;
  justify-content: center;

  // color
  background-color: #121212;

  z-index: 1;

  * {
    margin: 0;
  }
`

const FirstSection = styled.div`
  margin: 0 auto;
  padding: 0;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 25px;
  flex-direction: column;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 2;
`

const Intro = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
`

const SectionMessages = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
`

const ImageSectionIntro = styled.div`
  display: flex;
  flex: 1;
`

const IntroImage = styled.img`
  content: url(${erik});
  flex: 1;
  width: 150px;
  height: 100%;
  object-fit: cover;
  @media screen and (max-width: 600px) {
    width: 100px;
  }
  @media screen and (max-width: 400px) {
    width: 80%;
  }
`

const IntroTitle = styled.p`
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: 800;
  color: #fff;
  font-size: 40px;
  margin-left: 20px;

  padding: 0;
  margin-bottom: 25px;
  margin-top: 15px;

  @media screen and (max-width: 750px) {
    font-size: 35px;
  }

  @media screen and (max-width: 600px) {
    font-size: 32px;
  }
`

const TextBoxIntro = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const MessagesDiv = styled.div`
  flex-direction: column;
  margin-top: 20px;
  align-items: center;
  jusitfy-content: center;

  @media screen and (max-width: 600px) {
    margin-top: 0px;
  }
`

const MessagesImage = styled.img`
  content: url(${allMessages});
  flex: 1;
  width: 300px;
  height: 100%;
  object-fit: cover;
  @media screen and (max-width: 600px) {
    width: 250px;
  }
  @media screen and (max-width: 400px) {
    width: 200px;
  }
`

const MessagesDesc = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
`

const MessagesText = styled.p`
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: lighter;
  color: #fff;
  line-height: 2rem;
  font-size: 28px;
  margin-bottom: 25px;
  align-self: center;

  padding: 0;
  @media screen and (max-width: 750px) {
    font-size: 25px;

    line-height: 1.7rem;
  }

  @media screen and (max-width: 600px) {
    font-size: 20px;
    line-height: 1.5rem;

    margin-bottom: 30px;
  }
`

const QueueMessagesImage = styled.img`
  content: url(${queueMessages});
  flex: 1;
  width: 300px;
  height: 100%;
  object-fit: cover;
  @media screen and (max-width: 600px) {
    width: 250px;
  }
  @media screen and (max-width: 400px) {
    width: 200px;
  }
`

const SectionOne = styled.div`
  margin: 0 auto;
  padding: 0;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  z-index: 2;
`

const PositioningDiv = styled.div`
  margin: 0;
  z-index: 2;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
`

const SectionTwoButton = styled.div`
  margin-top: 0px;
  margin-bottom: 75px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;

  @media screen and (max-width: 600px) {
    display: flex;
    align-self: center;
  }
`

const SectionThree = styled.div`
  margin: 0 auto;

  margin-top: 50px;
  width: 100%;
  height: 100%;
  display: flex;
  padding-bottom: 0px;
  align-items: center;
  justify-content: center;
`

const SectionFour = styled.div`
  margin: 0 auto;

  margin-top: 75px;
  width: 100%;
  height: 100%;
  display: flex;
  padding-bottom: 80px;
  align-items: center;
  justify-content: center;
`

const ImageSectionOne = styled.div`
  display: flex;
  max-width: 960px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 30px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`

const MockupImageOne = styled.img`
  content: url(${mockup});
  flex: 1;
  width: 60%;
  height: 100%;
  object-fit: cover;
  @media screen and (max-width: 600px) {
    width: 70%;
  }
  @media screen and (max-width: 400px) {
    width: 80%;
  }
`

const SeperatorBox = styled.div`
  align-self: center;
  border-bottom: 4px solid #fdde68;
  width: 50px;
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 15px;
  display: none;
  @media screen and (max-width: 600px) {
    display: block;
    width: 55px;
  }
`

const ImageText = styled.p`
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: lighter;
  color: #fff;
  line-height: 2rem;
  font-size: 30px;
  margin-bottom: 20px;

  padding: 0;
  @media screen and (max-width: 750px) {
    font-size: 26px;

    line-height: 1.7rem;
  }

  @media screen and (max-width: 600px) {
    font-size: 22px;
    line-height: 1.5rem;
    text-align: center;

    margin-bottom: 30px;
  }
`

const TextBoxOne = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 600px) {
    max-width: 80%;
  }
`

const TextDiv = styled.div`
  margin: 0;
  align-self: left;
  width: 80%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

const Button = styled.div`
  z-index: 3;
  border: 4px solid #fdde68;
  width: 300px;
  height: 65px;
  border-radius: 6px;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-top: 10px;

  @media screen and (max-width: 750px) {
    width: 240px;
    height: 50px;
  }

  @media screen and (max-width: 660px) {
    width: 210px;
    height: 50px;
  }
`

const ButtonText = styled.p`
  justify-content: center;
  margin-right: 5px;
  text-align: center;
  line-height: 57px;
  align-items: center;
  font-family: Arial Black, Arial Bold, Gadget, sans-serif;
  font-size: 23px;
  color: #fff;

  @media screen and (max-width: 750px) {
    font-size: 20px;
    line-height: 42px;
    margin-right: 3px;
  }

  @media screen and (max-width: 660px) {
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif !important;
    font-weight: 900;
    font-size: 18px;
    line-height: 42px;
  }
`

const MessengerDiv = styled.div`
  justify-content: center;
  align-items: flex-start;
  margin-left: 5px;
  @media screen and (max-width: 750px) {
    margin-left: 3px;
  }
`

const MessengerIcon = styled.img`
  content: url(${messenger});

  max-width: 38px;
  height: 100%;
  object-fit: contain;
  @media screen and (max-width: 750px) {
    width: 30px;
  }
  @media screen and (max-width: 660px) {
    width: 25px;
  }
`

const Catchline = styled.h1`
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: lighter;
  color: #fdde68;
  font-size: 40px;

  margin: 0;
  padding: 0;
  margin-bottom: 25px;
  margin-top: 15px;

  @media screen and (max-width: 750px) {
    font-size: 35px;
  }

  @media screen and (max-width: 600px) {
    font-size: 32px;
  }
`

const QueueText = styled.h1`
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: lighter;
  color: #fdde68;
  font-size: 40px;

  margin: 0;
  padding: 0;

  @media screen and (max-width: 750px) {
    font-size: 26px;
  }

  @media screen and (max-width: 600px) {
    font-size: 32px;
  }
`

const InfluencerHeading = styled.h1`
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: lighter;
  color: #fdde68;
  font-size: 35px;

  margin: 0;
  padding: 0;

  @media screen and (max-width: 750px) {
    font-size: 30px;
  }

  @media screen and (max-width: 600px) {
    font-size: 26px;
  }
`

const Seperator = styled.p`
  border-bottom: 4px solid #fdde68;
  width: 50px;
  margin-left: 2px;
  margin-top: 15px;
  margin-bottom: 15px;
  @media screen and (max-width: 600px) {
    width: 55px;
  }
`

const Emoji = styled.p`
  font-size: 40px;
  margin-top: 50px;
  margin-bottom: 30px;
  margin-left: 3px;
`

const Desc = styled.h1`
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: lighter;
  color: #fff;
  line-height: 2rem;
  font-size: 28px;
  margin-bottom: 25px;

  padding: 0;
  @media screen and (max-width: 750px) {
    font-size: 25px;

    line-height: 1.7rem;
  }

  @media screen and (max-width: 600px) {
    font-size: 20px;
    line-height: 1.5rem;

    margin-bottom: 30px;
  }
`

const ThickHeading = styled.h1`
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: lighter;
  color: #fff;
  line-height: 2rem;
  font-size: 30px;
  margin-bottom: 20px;

  padding: 0;
  @media screen and (max-width: 750px) {
    font-size: 26px;

    line-height: 1.7rem;
  }

  @media screen and (max-width: 600px) {
    font-size: 22px;
    line-height: 1.5rem;

    margin-bottom: 30px;
  }
`

const Yellow = styled.span`
  color: #fdde68;
`

const White = styled.span`
  color: #fff;
`

const LinkInsta = styled(props => <a {...props} />)`
  // resetting
  margin: 0;
  padding: 0;

  // typography
  font-size: 1.2rem;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: lighter;
  color: #fff;

  margin: 0;
  padding: 0;

  margin-bottom: 30px;

  // color

  // display-options
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 30px;

  padding: 0;
  @media screen and (max-width: 750px) {
    font-size: 26px;
  }

  @media screen and (max-width: 600px) {
    font-size: 22px;

    margin-bottom: 30px;
  }
`
